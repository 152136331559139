import { Controller } from "@hotwired/stimulus";

type Action = "unwrap" | "steal";

export default class extends Controller {
  declare actionTarget: HTMLButtonElement;
  declare wrappedTarget: HTMLElement;
  declare unwrappedTarget: HTMLElement;
  declare smokeTarget: HTMLImageElement;
  declare idValue: number;
  declare wrappedValue: boolean;
  declare wiggleClass: string;
  declare hiddenClass: string;
  declare opacityClass: string;

  static targets = ["action", "smoke", "wrapped", "unwrapped"];
  static classes = ["wiggle", "hidden", "opacity"];
  static values = {
    id: Number,
    wrapped: Boolean,
  };

  handleActionMouseOver({ params }) {
    if (this.wrappedValue) {
      this.wrappedTarget.classList.toggle(
        this.wiggleClass,
        params.action === "unwrap"
      );
    }
  }

  handleActionMouseOut() {
    this.wrappedTarget.classList.remove(this.wiggleClass);
  }

  handleActionClick(event) {
    event.preventDefault();
    this.actions?.[event.params.action as Action]();
    setTimeout(() => {
      event.target.parentElement.requestSubmit();
    }, 1000);
  }

  private get actions(): Record<Action, Function> {
    return {
      unwrap: this.unwrapPresent.bind(this),
      steal: this.stealPresent.bind(this),
    };
  }

  private unwrapPresent() {
    this.wrappedTarget.classList.remove(this.wiggleClass);
    this.wrappedValue = false;

    requestAnimationFrame(() => {
      this.wrappedTarget.classList.add(this.hiddenClass);
      this.smokeTarget.classList.remove("hidden");

      setTimeout(() => {
        this.smokeTarget.classList.add("hidden");
      }, 450);

      setTimeout(() => {
        this.unwrappedTarget.classList.remove(this.hiddenClass);
      }, 100);

      setTimeout(() => {
        this.unwrappedTarget
          .querySelector("img")
          ?.classList.remove(this.opacityClass);
      }, 750);
    });
  }

  private stealPresent() {
    console.log("stealin ya shit!");
  }
}
