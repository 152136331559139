import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare elementTargets: HTMLElement[];
  static values = {
    id: String
  }

  static targets = ["element"];

  elementTargetConnected(element: HTMLElement) {
    if (element.getAttribute('data-apply-to-participant-id') == this.idValue) {
      element.classList.remove('hidden');
      element.parentElement.classList.add('z-10');
    }
  }

}
