import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare inputTarget: HTMLInputElement;
  declare inputLabelTarget: HTMLLabelElement;
  declare outputTarget: HTMLImageElement;
  declare newImageTarget: HTMLButtonElement;
  declare filenameTarget: HTMLSpanElement;
  declare hiddenClass: string;
  declare hidingClasses: string[];
  declare populatedClasses: string[];
  declare baseImageValue: string;
  declare providedImageValue: string;

  static targets = ["output", "input", "inputLabel", "newImage", "filename"];
  static classes = ["hidden", "hiding", "populated"];
  static values = {
    baseImage: String,
    providedImage: String,
  };

  private get hasImage() {
    return this.providedImageValue.length > 0;
  }

  readURL() {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      this.outputTarget.classList.add(...this.hidingClasses);

      setTimeout(() => {
        const reader = new FileReader();

        reader.onload = () => {
          this.outputTarget.src = reader.result as string;
          this.outputTarget.classList.remove(...this.hidingClasses);
          this.providedImageValue = this.inputTarget.files[0].name;
        };

        reader.onloadstart = () => {};

        reader.readAsDataURL(this.inputTarget.files[0]);
      }, 750);
    }
  }

  reset() {
    this.outputTarget.classList.add(...this.hidingClasses);

    setTimeout(() => {
      this.outputTarget.src = this.baseImageValue;
      this.outputTarget.classList.remove(...this.hidingClasses);
      this.inputTarget.value = "";
      this.providedImageValue = "";
      this.providedImageValue = "";
    }, 750);
  }

  providedImageValueChanged() {
    const hasImage = this.hasImage;

    this.populatedClasses.forEach((className) => {
      this.outputTarget.classList.toggle(className, hasImage);
    });

    this.filenameTarget.innerText = decodeURI(
      this.providedImageValue.split("/").slice(-1)[0]
    );
    this.toggle(this.filenameTarget, !this.hasImage);
    this.toggle(this.inputLabelTarget, this.hasImage);
    this.toggleField(this.inputTarget, this.hasImage);
    this.toggleField(this.newImageTarget, !this.hasImage);
  }

  private toggleField(
    field: HTMLInputElement | HTMLButtonElement,
    isDisabled: boolean
  ) {
    this.toggle(field, isDisabled);

    switch (field.tagName.toLowerCase()) {
      case "input":
        (field as HTMLInputElement).readOnly = isDisabled;
        break;
      case "button":
        (field as HTMLButtonElement).disabled = isDisabled;
        break;
    }
  }

  private toggle(element: HTMLElement, isHidden: boolean) {
    element.classList.toggle(this.hiddenClass, isHidden);
  }
}
