import { Controller } from "@hotwired/stimulus";
import { DataTable } from "simple-datatables";

export default class extends Controller {
  declare datatable: DataTable;

  initialize() {
    this.datatable = new DataTable(this.element as HTMLElement);
  }
}
