import { Controller } from "@hotwired/stimulus";
import { FormSubmissionResult } from "@hotwired/turbo/dist/types/core/drive/form_submission";

// Connects to data-controller="remote-modal"
export default class extends Controller {
  hideModal() {
    this.resetModal();
  }

  resetModal() {
    this.element.parentElement.removeChild(this.element);
  }

  handlePostSubmit({ detail }: CustomEvent<FormSubmissionResult>) {
    if (detail.success) this.hideModal();
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal();
    }
  }
}
